.experience {
	@include container;
	margin: $xl-marg auto;
	h2 {
		font-size: $sub-heading-font;
		margin-bottom: -3px;
		color: $blue;
		text-align: center;
	}

	a {
		color: $half-white;
		text-decoration: none;
		&:hover {
			color: $blue;
			text-decoration: none;
		}
		margin-left: $md-marg;
	}
}
