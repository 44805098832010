//font sizes
$sm-font: 14px;
$md-font: 18px;
$lg-font: 24px;
$xl-font: 105px;
$sub-heading-font: 40px;

//paddings
$sm-pad: 2.5px;
$md-pad: 8.5px;
$lg-pad: 16.5px;
$xl-pad: 19.5px;

//margins
$sm-marg: 2.5px;
$md-marg: 8.5px;
$lg-marg: 16.5px;
$xl-marg: 30.5px;
$xxl-marg: 40px;

//border radius
$b-radius: 18px;
$sm-b-radius: 5px;

//colors
$dark: #303841;
$blue: #8fadff;
$half-white: #eeeeee;
$light-dark: #3a4750;
$light-blue: rgba(86, 131, 255, 0.2);
