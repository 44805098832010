.footer {
	max-width: 150px;
	margin: 30px auto;
	text-align: center;
	a {
		text-decoration: none;
		margin-top: 30px;
		font-size: $sm-font;
		color: $blue;
		letter-spacing: 1px;
		line-height: 15px;
		cursor: pointer;
		&:hover {
			opacity: 0.6;
		}
	}
}
