.aboutme {
	cursor: default;
	max-width: 1100px;
	margin: 0 auto;
	h2 {
		margin-top: 30px;
		font-size: $sub-heading-font;
		margin-bottom: 10px;
		color: $blue;
		text-align: center;
		max-width: 600px;
	}
	.aboutme-grid {
		@include grid;
		grid-template-columns: 2fr 1.2fr;
		place-items: center;
		margin-bottom: 200px;
		.content {
			p {
				text-align: justify;
				padding: $md-pad 0;
				font-size: 16.5px;
				a {
					color: $blue;
					text-decoration: none;
					&:hover {
						text-decoration: underline;
					}
				}
				b {
					color: $blue;
				}
			}

			ul {
				list-style-type: none;
				@include grid;
				margin-top: $lg-marg;
				gap: 1rem;
				grid-template-columns: repeat(4, 1fr);
				// max-width: 500px;

				li {
					cursor: default;
					// b {
					@include button-style-content;
					font-weight: 500;
					font-size: 13px;
					white-space: nowrap;
					text-align: center;
					// }
				}
				@include smallerThan(800px) {
					grid-template-columns: repeat(3, 1fr);
					li {
						b {
							font-size: 12px;
						}
					}
				}
				@include smallerThan(500px) {
					grid-template-columns: repeat(2, 1fr);
					// place-items: center;
					li {
						b {
							font-size: 10px;
						}
					}
				}
			}
		}
		.image {
			img {
				max-width: 355px;
				min-width: 0px;
				border-radius: 50%;
				object-fit: cover;
			}
			position: relative;
			&::after {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				background-color: $light-blue;
				width: 100%;
				height: 99%;
				border-radius: 50%;
			}
			&:hover::after {
				opacity: 0;
			}

			//media query
			@include smallerThan(400px) {
				img {
					max-width: 280px;
				}
			}
		}

		@include smallerThan(820px) {
			grid-template-columns: 1fr;
			margin-bottom: 100px;
			.content {
				order: 2;
			}
			.image {
				order: 1;
			}
		}
	}
	@include smallerThan(820px) {
		h2 {
			// margin-top: 0px;
			max-width: 100vw;
			// text-align: left;
			margin-bottom: 30px;
		}
	}
	//media query
	@include smallerThan(500px) {
		padding: 0.5rem;
	}
}
