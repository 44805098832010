.intro {
	@include flex-center;
	align-items: flex-start;
	flex-direction: column;
	height: 100vh;
	transform: translateY(-50px);
	animation: opacity-fade-anim 2.4s forwards;
	h1 {
		font-size: $xl-font;
		color: $blue;
	}
	h2 {
		font-size: $xl-font;
		color: $blue;
		opacity: 0.6;
	}
	p:nth-child(1) {
		font-size: $sm-font;
	}
	p {
		max-width: 550px;
		font-size: 15.5px;
		line-height: 1.3;
		text-align: justify;
	}
	button {
		@include button;
		margin: $lg-marg 0;
	}

	@include smallerThan(1050px) {
		h1,
		h2 {
			font-size: 90px;
		}
	}

	@include smallerThan(800px) {
		h1,
		h2 {
			font-size: 80px;
		}
	}

	@include smallerThan(720px) {
		h1,
		h2 {
			font-size: 50px;
		}
		p {
			padding: 10px 0;
		}
	}

	@include smallerThan(500px) {
		h1,
		h2 {
			font-size: 35px;
		}
		p {
			font-size: $sm-font;
		}
	}
	//media query
	@include smallerThan(350px) {
		h1,
		h2 {
			font-size: 29px;
		}
	}

	@keyframes opacity-fade-anim {
		from {
			opacity: 0;
		}
		to {
			opacity: 1;
		}
	}
}
