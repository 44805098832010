.tabs {
	.tabs-items {
		list-style-type: none;
		display: flex;
		max-width: 500px;
		margin: $xl-marg auto;
		// justify-content: center;
		li {
			@include button;
			white-space: nowrap;
			border: 0;
			font-weight: 400;
			font-size: $md-font;
			margin-right: $sm-marg;
		}
		overflow-x: auto;
		&::-webkit-scrollbar {
			display: none;
		}
		.active-tab {
			@include button;
			border: 0;
			font-weight: 400;
			font-size: $md-font;
			background-color: $light-blue;
		}
	}

	.content {
		opacity: 0;
		@include container;
		max-width: 750px;
		margin: $xl-marg auto;
		justify-content: flex-start;
		@include card;
		&:hover {
			transform: none;
		}
		h3 {
			font-weight: 500;
			margin-bottom: $sm-marg;
			color: $blue;
			font-size: $lg-font;
		}
		h4 {
			font-weight: 500;
			margin-top: $md-marg;
			color: $blue;
			// font-size: $md-font;
			margin-bottom: 50px;
		}
		p {
			font-style: italic;
			opacity: 0.85;
			font-size: $sm-font;
		}
		.content-learned-items {
			margin-top: 40px;
			ul {
				list-style-type: none;
				li {
					font-size: 16px;
					@include grid;
					grid-template-columns: 0.5fr 15fr;
					place-items: start;
					letter-spacing: 1.1px;
					margin: 12px 0;
					text-align: justify;
					.suqaredbullet-svg {
						margin-top: 4.5px;
					}
				}
			}
		}
	}
}
